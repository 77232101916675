import React from 'react';
import SplashNav from '../components/SplashNav';
import PersonCard from '../components/PersonCard';
import data from '../data/sampleData.json';
import './AthenaPage.css';

const AthenaPage = () => {
    return (
        <div className="athena-page">
            <div className="page-content fade-in">
                <div className="title-section">
                    <img src="/athena-logo.png" alt="Athena Logo" className="logo-image" />
                    <h2 className="page-title">Athena: A Service to Characterize Individuals</h2>
                    <h6 className="subtitle">The Data Below Represents a Limited Sample of the capabilities of the Athena Program</h6>
                    <p className="disclaimer">
                        While the underlying data displayed in this sample is authentic, personal information has been modified to protect individual privacy. Athena is a tool developed to assist users in gaining deeper insights into individuals within their own datasets or in combination with our other data services.
                    </p>
                </div>
                <div className="person-cards">
                    {data.map((person, index) => (
                        <PersonCard key={index} person={person} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AthenaPage;
