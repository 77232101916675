import React, { createContext, useState, useContext } from "react";

// Create the context
const WalletContext = createContext();

// Create a provider component
export const WalletProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  // Connect wallet logic
  const connectWallet = async () => {
    try {
      const { solana } = window;
      if (solana && solana.isPhantom) {
        const response = await solana.connect();
        setWalletAddress(response.publicKey.toString());
        setIsConnected(true);
      } else {
        alert("Phantom Wallet not found. Please install it.");
      }
    } catch (err) {
      console.error("Failed to connect wallet:", err);
    }
  };

  // Disconnect wallet logic
  const disconnectWallet = () => {
    setWalletAddress("");
    setIsConnected(false);
  };

  return (
    <WalletContext.Provider value={{ isConnected, walletAddress, connectWallet, disconnectWallet }}>
      {children}
    </WalletContext.Provider>
  );
};

// Custom hook to use the wallet context
export const useWallet = () => useContext(WalletContext);
