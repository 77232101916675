import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../context/WalletContext"; // Import WalletContext
import "./SplashNav.css";

export default function SplashNav(props) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const scrollY = props.scrollY ? props.scrollY : 0;

    // Wallet context
    const { isConnected, walletAddress, connectWallet, disconnectWallet } = useWallet();

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const toggleServices = () => {
        setIsServicesOpen((prevState) => !prevState);
    };

    return (
            <header
                className={`splash-container ${
                    scrollY > 50 ? "scrolled" : ""
                }`}
            >

            <div className={`splash ${scrollY * 6.5 > 200 ? "no-pad-smooth" : "re-pad-smooth"}`}>
                <div className="splash-logo">
                    <img src="/favicon.ico" alt="Prometheus Logo" className="logo" />
                    <span className="logo-text" onClick={() => navigate("/")}>
                        PROMETHEUS
                    </span>
                </div>

                <div className="splash-actions">

                    {/* Hamburger Menu Icon (Mobile Only) */}
                    <div className="splash-menu-icon mobile-only" onClick={toggleMenu}>
                        <div className="hexagon">
                            <div className="menu-lines"></div>
                        </div>
                    </div>
                </div>

                {/* Menu Items */}
                <nav className={`splash-menu ${isMenuOpen ? "active" : ""}`}>
                    {/* Services Dropdown */}
                    <div className="services-dropdown">
                        <button
                            className="splash-contact my-2"
                            onClick={toggleServices}
                        >
                            SAMPLES
                        </button>
                        <ul className={`dropdown-menu ${isServicesOpen ? 'active' : ''}`}>
                            <li>
                                <button
                                    className="splash-contact sm"
                                    onClick={() => {
                                        navigate("/athena-sample");
                                        setIsMenuOpen(false);
                                        toggleServices();
                                      }}
                                >
                                    ATHENA
                                </button>
                            </li>
                            <li>
                                <button
                                    className="splash-contact sm"
                                    onClick={() => {navigate("/artemis");
                                    setIsMenuOpen(false);
                                    toggleServices();}}
                                >
                                    ARTEMIS
                                </button>
                            </li>
                            <li>
                                <button
                                    className="splash-contact sm"
                                    onClick={() => {
                                        navigate("/engage-sample");
                                        setIsMenuOpen(false);
                                        toggleServices();
                                    }}
                                >
                                    ENGAGE
                                </button>
                            </li>
                            <li>
                                <button
                                    className="splash-contact sm"
                                    onClick={() => {
                                        navigate("/harvest-sample");
                                        setIsMenuOpen(false);
                                        toggleServices();
                                    }}
                                >
                                    HARVEST
                                </button>
                            </li>
                            <li>
                                <button
                                    className="splash-contact sm"
                                    onClick={() => {
                                        navigate("/mine-sample");
                                        setIsMenuOpen(false);
                                        toggleServices();
                                    }}
                                >
                                    MINE
                                </button>
                            </li>
                        </ul>
                    </div>

                    <li>
                        <button className="splash-contact" onClick={() => {
                            navigate("/profile");
                            setIsMenuOpen(false);}}>
                            PROFILE
                        </button>
                    </li>
                    <li>
                        <button className="splash-contact" onClick={() => { 
                        navigate("/$pvp");
                        setIsMenuOpen(false);
                    }}>
                            PVP
                        </button>
                    </li>
                    {/* <li>
                        <button className="splash-contact" onClick={() => navigate("/about-us")}>
                            ABOUT
                        </button>
                    </li> */}
                    <li>
                        <button className="splash-contact" onClick={() => {
                            navigate("/$PL");
                            setIsMenuOpen(false);
                            }}>
                            $PL
                        </button>
                    </li>
                    <li>
                        <button className="splash-contact" onClick={() => {
                            navigate("/privacy");
                            setIsMenuOpen(false);}}>
                            PRIVACY POLICY
                        </button>
                    </li>
                    {/* <li>
                        <button className="splash-contact" onClick={() => navigate("/contact")}>
                            CONTACT
                        </button>
                    </li> */}
                    <li>
                        <button className="splash-contact" onClick={() => {
                            navigate("/login");
                            setIsMenuOpen(false);
                            }}>
                            MEMBERS
                        </button>
                    </li>
                                        {/* Wallet Button */}
                                        <div className="wallet-container">
                        {isConnected ? (
                            <button className="wallet-button connected" onClick={disconnectWallet}>
                                {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
                            </button>
                        ) : (
                            <button className="wallet-button" onClick={connectWallet}>
                                Connect
                            </button>
                        )}
                    </div>
                </nav>
            </div>
        </header>
    );
}
