import React, { useEffect, useState } from 'react';
import './SplashPage.css';
import { useNavigate } from 'react-router-dom';
import SplashNav from '../components/SplashNav';

export default function SplashPage() {
    const navigate = useNavigate();
    const [rotation, setRotation] = useState(0);
    const [flippedIndex, setFlippedIndex] = useState([true, true, true, true, true, true]);
    const [scrollY, setScrollY] = useState(0);

    // Handle scroll to update rotation and track scrollY
    useEffect(() => {
        const handleScroll = () => {
            const newScrollY = window.scrollY;
            setScrollY(newScrollY);
            setRotation(newScrollY / 6.5); // Adjust the rotation speed based on scroll
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleFlippedIndex = (index) => {
        const newFlippedIndex = [...flippedIndex];
        newFlippedIndex[index] = !flippedIndex[index];
        setFlippedIndex(newFlippedIndex);
    };
    

    return (
        <div className="splash-page">
            {/* <SplashNav scrollY={scrollY} /> */}
            {/* Hero Section */}
            <section className="hero-section">
                <div className={`${rotation * 6.5 > 150 ? "fade-out" : "fade-in"}`}>
                    <div className="hero-content">
                        <h4 className='home-title bold'>Providing Businesses<br /><br /> With Creative Intelligence</h4>
                        <p className="small-text text-light mt-4">Already a Member?</p>
                        <button className="cta-button" onClick={() => navigate('/login')}>
                            Get Started
                        </button>
                    </div>
                </div>
            </section>

            {/* Pyramid Background */}
            <div
                className="pyramid mx-auto"
                style={{
                    transform: `rotateY(${rotation}deg) rotateX(${rotation / 3}deg)`,
                }}
            >
                <div className="face face-front">
                </div>
                <div className="face face-right"></div>
                <div className="face face-back"></div>
                <div className="face face-left"></div>
                <div className="face face-base">
                </div>
            </div>


            {/* Features Section */}
            <section className="features-section">
                <div className="features-container">
                    <div className="feature-card">
                        <img src="/hive.png" className="feature-icon" alt="Social Analysis" />
                        <h3>Social Analysis</h3>
                        <p>Understand a social group in detail using personal information and clustering algorithms.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/target.png" className="feature-icon" alt="Advanced Targeting" />
                        <h3>Advanced Targeting</h3>
                        <p>Use interests and financial profiles to target the correct people.</p>
                    </div>
                    <div className="feature-card">
                        <img src="/lotus.png" className="feature-icon" alt="Creative Consulting" />
                        <h3>Creative Consulting</h3>
                        <p>Our team of SMEs have a track record of solving previously impossible tasks.</p>
                    </div>
                </div>
            </section>
            <section className="product-section text-center">
                <h4 className="section-title mobile-small">
                    Acquire Truths. 
                    Harvest Evidence. 
                    Navigate Assertively.
                </h4>
            </section>
            {/* Product Section */}
            <section className="product-section">
                <div className="product-container">
                    <div className="product-highlights">
                        <div className="product-card-small">
                            <img src="/network.png" className="product-icon" alt="Network Feature" />
                            <p>All data-driven insights are procured with the highest standards of integrity and rigorously tested to ensure accuracy.</p>
                        </div>
                        <div className="product-card-small">
                            <img src="/pillar.png" className="product-icon" alt="Pillar Feature" />
                            <p>Fueled by integrity and work ethic, our team will find the answers to your most challenging problems.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-header text-center">
                <h4 className="section-title mobile-small my-5">Services</h4>
            </section>
            {/* Products Overview Section */}
            <section className="products-overview-section">
                <div className="products-container">

                    {/* Extract Card */}
                    <div className="product-card">
                        {flippedIndex[0] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(0)}>
                                <div className="product-icon">
                                    <img src="/Extract.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Data Extraction</h3>
                                    <p className="special-name">Extract</p>
                                    <p>Extract Specific Data from Online Resources</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(0)}>
                                <div className="product-icon">
                                    <img src="/Extract.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Extract</p>
                                    <p>Extracted 3,000,000 American Voters based on voter preferences from social media platforms.<br /><br />For Sample Data Submit a Request.</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Mine Card */}
                    <div className="product-card">
                        {flippedIndex[1] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(1)}>
                                <div className="product-icon">
                                    <img src="/Mine.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Data Mining</h3>
                                    <p className="special-name">Mine</p>
                                    <p>Transform raw data into actionable intelligence for precision targeting and personalized engagement.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(1)}>
                                <div className="product-icon">
                                    <img src="/Mine-flipped.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Mine</p>
                                    <p>Extracted and analyzed 100,000 competitors petroleum licenses for a client in the oil and gas industry.<br /><br />For Sample Data Submit a Request.</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Harvest Card */}
                    <div className="product-card">
                        {flippedIndex[2] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(2)}>
                                <div className="product-icon">
                                    <img src="/harvest.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Targeted Lists</h3>
                                    <p className="special-name">Harvest</p>
                                    <p>Precision-driven lists of potential customers, segmented by geography, behavior, and demographics.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(2)}>
                                <div className="product-icon">
                                    <img src="/harvest-flipped.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Harvest</p>
                                    <p>identified 1,000,000 Catholic Voters with a low voting propensity in key swing states.<br /><br />For Sample Data Submit a Request.</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Engage Card */}
                    <div className="product-card">
                        {flippedIndex[3] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(3)}>
                                <div className="product-icon">
                                    <img src="/engage.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Geofencing</h3>
                                    <p className="special-name">Engage</p>
                                    <p>Deliver location-specific promotions and advertisements to potential customers in real-time.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(3)}>
                                <div className="product-icon">
                                    <img src="/engage.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Engage</p>
                                    <p>Mined every local resident within 2 kilometer radii of physical store fronts.</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Navigate Card */}
                    <div className="product-card">
                        {flippedIndex[4] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(4)}>
                                <div className="product-icon">
                                    <img src="/artemis.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Hunt</h3>
                                    <p className="special-name">Artemis</p>
                                    <p>The ultimate solution to digital marketing. Custom tailored to E-Commerce.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(4)}>
                                <div className="product-icon">
                                    <img src="/artemis-flipped.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Artemis</p>
                                    <p>Increased site traffic by over 1000% for a jewelry brand in Athens, Greece.</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Anchor Card */}
                    <div className="product-card">
                        {flippedIndex[5] ? (
                            <div className="product-card flipped" onClick={() => handleFlippedIndex(5)}>
                                <div className="product-icon">
                                    <img src="/athena-logo.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <h3>Wisdom</h3>
                                    <p className="special-name">Athena</p>
                                    <p>Go beyond basic information to understand an individual's unique thought processes.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="product-card back" onClick={() => handleFlippedIndex(5)}>
                                <div className="product-icon">
                                    <img src="/athena-logo-flipped.png" className="product-icon-img" alt="Pillar Feature" />
                                </div>
                                <div className="product-details">
                                    <p className="special-name">Athena</p>
                                    <p>Analyzed thousands of related persons comprehensive personality profiles to establish a means of swaying the group in a favorable direction.</p>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </section>




        {/* Text Section */}
        <section className="text-section">
            <div className="product-container">
                <div className="product-card-text center py-5">
                    <h1 className='text-list-item bold mb-3'>Trusted By:</h1>
                    <h2 className='text-list-item my-3'>Political Organizations</h2>
                    <h2 className='text-list-item my-3'>Industry Leading CEOs</h2>
                    <h2 className='text-list-item my-3'>Individuals of Special Interest</h2>
                </div>
            </div>
        </section>



        {/* Footer Section */}
        <footer className="footer">
            <div className="footer-container">
                <h6>Contact Us at <a className='text-white bold' href="mailto:info@prometheuslabs.app">info@prometheuslabs.app</a></h6>
                <p>Please include a detailed description of your business needs.</p>
                <p>Prometheus Access Is Limited to Active Members. Contact Us to Learn More.</p>
                <p>© 2024 Prometheus Labs. All rights reserved.</p>
            </div>
        </footer>

        </div>
    );
}
