import React from 'react';
import SplashNav from '../components/SplashNav';
import UserIntentCard from '../components/UserIntentCard';
import intentData from '../data/intentData.json'; // Sample intent data
import CostDiagram from '../components/CostDiagram'; // Import the CostDiagram component
import './ArtemisPage.css';

const ArtemisPage = () => {
    return (
        <div className="artemis-page">
            <div className="page-content fade-in row">
            <div className="title-section row">
                    <img src="/artemis.png" alt="Artemis Logo" className="logo-image" />
                    <h2 className="page-title">Artemis: The Solution to Customer Acquisition</h2>
                    <p className="description">Data is delivered pre-split into specific compartments, each with a unique purpose.
                    Conduct testing using email and text message campaigns to lower the cost of finding your ideal niche.</p>
            </div>
                <div className="three-step-process">
                    <div className="process-step">
                        <h3>Domain Warming</h3>
                        <p>Domain Warming is a crucial first step in building trust with email service providers and ensuring your messages consistently reach your target audience's inboxes. This process involves gradually increasing the volume of emails sent from a new domain or IP address over time. By slowly ramping up your email activity, domain warming helps establish a positive sending reputation with email servers. This is particularly important because email service providers monitor sender behavior closely to prevent spam, and sudden, high-volume email sends from a new domain can be flagged as suspicious.</p>
                    </div>
                    
                    <div className="process-step">
                        <h3>A/B Testing</h3>
                        <p>A/B Testing is a powerful technique for optimizing email campaigns by experimenting with different versions of content to determine which performs best with your audience. In this process, two or more variations of an email—differing in elements like subject lines, messaging, images, or calls-to-action—are sent to segments of your audience to gauge engagement metrics such as open rates, click-through rates, and conversions. By analyzing the results, A/B testing helps you understand which specific components resonate most effectively, allowing you to tailor your messaging to better capture audience interest and drive action. This data-driven approach leads to more refined, personalized communications, enhancing overall campaign performance and ensuring that your outreach aligns closely with your audience's preferences and behaviors.</p>
                    </div>
                    
                    <div className="process-step">
                        <h3>Weekly Campaigning</h3>
                        <p>Weekly Campaigning maintains ongoing engagement by delivering consistent, relevant content to your audience at regular intervals. This approach ensures that your brand remains top-of-mind, nurturing relationships and building trust over time. By crafting tailored messages for each week, you can keep your audience updated on new products, special offers, industry insights, or other value-driven content that encourages interaction. Weekly campaigning also allows you to track engagement trends and adapt your messaging based on real-time feedback, creating a dynamic conversation with your audience. This steady, thoughtful presence reinforces brand loyalty and encourages ongoing interest, leading to higher retention rates and a more engaged community.</p>
                    </div>
                </div>

                <div className='my-3'>
                    <CostDiagram />
                </div>
                <div className="title-section row">
                    <img src="/artemis.png" alt="Artemis Logo" className="logo-image" /><img src="/harvest.png" alt="Harest Logo" className="logo-image" />
                    <h2 className="page-title">Artemis + Harvest</h2>
                    <p className="description">The Harvest Program is designed to gather and refine high-quality intent data, which can seamlessly feed into the Artemis program to optimize targeted outreach. Harvest identifies key insights about audience behavior and interests, enabling Artemis to use this data for tailored campaigns that drive sustained engagement and site traffic. While Harvest and Artemis work powerfully together to enhance data-driven marketing efforts, each program can also operate independently. Harvest can be used as a standalone tool for data collection and analysis, while Artemis can execute strategic marketing initiatives based on existing data, making them versatile solutions for businesses at different stages of their marketing journey.</p>
                </div>
                <div className="intent-cards">
                    {intentData.map((user, index) => (
                        <UserIntentCard key={index} user={user} />
                    ))}
                </div>

            </div>
        </div>
    );
};

export default ArtemisPage;
