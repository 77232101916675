import React from 'react';
import SplashNav from '../components/SplashNav';
import UserIntentCard from '../components/UserIntentCard';
import intentData from '../data/intentData.json'; // Sample intent data
import './ArtemisPage.css';

const PL = () => {
    return (
        <div className="artemis-page">
            <div className="page-content fade-in row">
            <div className="title-section row">
                    <img src="/pl.png" alt="Artemis Logo" className="logo-image" />
                    <h2 className="page-title">$PL: Onboard New Users to Grow Your Community</h2>
                    <p className="description">$PL Token is designed to help anyone from NFT holders to L1 Founders expand their audience and onboard new people to Web3. Powered by our extensive database containing PII on more than 200 million individuals and counting, $PL will be the catalyst for mass onboarding to Web3 by offering the first way to do direct marketing to Web2 Audiences. $PL will be available Q2 2025.</p>
            </div>
                <div className="three-step-process">
                    <div className="process-step">
                        <h3>Utility</h3>
                        <p>$PL Tokens serve as a powerful tool for accessing exclusive datasets containing detailed demographic and personally identifiable information (PII) on individuals. These tokens enable users to acquire valuable insights tailored to their specific needs. With just 1 $PL, users can perform basic skip tracing, which provides essential information for locating individuals. For those seeking more in-depth and customized demographic data, 10 $PL unlocks a wealth of comprehensive details designed to meet advanced research and analytical requirements. This flexibility ensures that $PL Tokens cater to a wide range of use cases, from simple searches to complex data-driven projects.</p>
                    </div>
                    
                    <div className="process-step">
                        <h3>Governance</h3>
                        <p>Token holders play a crucial role in shaping the future of the ecosystem by participating in governance through voting. They have the power to influence key decisions, including database expansions to enhance the range and quality of available datasets, strategic partnerships that drive growth and innovation, and the structuring of airdrops to reward community engagement and attract new participants. Additionally, token holders can vote on client engagement strategies, ensuring the platform aligns with user needs and market demands. This democratic approach empowers the community to actively guide the platform's development and foster a sense of collective ownership.</p>
                    </div>
                    
                    <div className="process-step">
                        <h3>Early Access</h3>
                        <p>Eligibility for early access is extended to key contributors and stakeholders who play a vital role in the platform’s ecosystem. Seed round capital investors are rewarded for their early support and belief in the project’s vision, providing the foundational funding necessary for growth. VNDL NFT holders and PVP players also qualify for $PL airdrop, as their ownership represents a commitment to the platform and its community-driven initiatives. Additionally, strategic partners are eligible, recognizing their collaborative efforts in driving innovation, expanding the platform's reach, and fostering valuable connections. Together, these categories ensure that rewards are distributed to those who have actively contributed to the platform's success.</p>
                    </div>
                </div>

                <div className="title-section row">
                    <h2 className="page-title">Example Data</h2>
                    <p className="description">Reach your audience directly. Using $PL tokens you skip the arduous task of endlessly creating content in the hopes of attracting user engagement. Send your message directly to your future consumers and community members. $PL is designed to be the catalyst to the expansion of the Web3 community. Together we can thrive.</p>
                </div>
                <div className="intent-cards">
                    {intentData.map((user, index) => (
                        <UserIntentCard key={index} user={user} />
                    ))}
                </div>

            </div>
        </div>
    );
};

export default PL;
