import React, { useEffect, useState } from 'react';
import { useWallet } from '../context/WalletContext'; // Assuming WalletContext provides the wallet address
import './PLProfilePage.css';
import CRYPTO_API from "../config_crypto";

const PLProfilePage = () => {
  const { walletAddress } = useWallet(); // Fetch wallet address from context
  const [wagers, setWagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWagers = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(
          `${CRYPTO_API}/wagers?wallet=${walletAddress}`
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data)
        setWagers(data.wagers || []); // Update wagers state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (walletAddress) {
      fetchWagers(); // Trigger fetch only if wallet is available
    }
  }, [walletAddress]);

  return (
    <div className="pl-profile-page">
      <div className="wallet-display text-white">
        <strong>Wallet:</strong> {walletAddress || 'Not Connected'}
      </div>
      {loading ? (
        <div className="loading w-100">Loading Activity</div>
      ) : error ? (
        <div className="error">Error: {error}</div>
      ) : (
        <div className="wager-table-container">
          {wagers.length > 0 ? (
            <table className="wager-table">
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Wager</th>
                  <th>Token</th>
                  <th>Side</th>
                  <th>Transaction</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {wagers.map((wager, index) => {
                    console.log("Rendering wager:", wager); // Debug log
                    return (
                    <tr key={index}>
                        <td>{wager.title || "N/A"}</td>
                        <td>{wager.wager || "N/A"}</td>
                        <td>{wager.token || "N/A"}</td>
                        <td>{wager.side || "N/A"}</td>
                        <td>
                        <a
                            href={`https://explorer.solana.com/tx/${wager.transaction_signature}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {wager.transaction_signature
                            ? `${wager.transaction_signature.slice(0, 6)}...${wager.transaction_signature.slice(-4)}`
                            : "N/A"}
                        </a>
                        </td>
                        <td>{wager.timestamp ? new Date(wager.timestamp).toLocaleString() : "N/A"}</td>
                    </tr>
                    );
                })}
                </tbody>

            </table>
          ) : (
            <div>No wagers found for this wallet.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default PLProfilePage;
