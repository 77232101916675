import React, { useState,useEffect } from "react";
import "./PVP.css";
import { useWallet } from "../context/WalletContext";
import {
  // Connection,
  SystemProgram,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import {
  createTransferInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import CRYPTO_API from "../config_crypto";
import axios from "axios";

const PVP = () => {
  const [games, setGames] = useState([]);
  const { isConnected, walletAddress } = useWallet();
  const [loadingGames, setLoadingGames] = useState({});
  const [wagers, setWagers] = useState({});
  const [transactionStatuses, setTransactionStatuses] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const buyAlpha = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000); // Auto-hide the popup after 2 seconds
  };

  const handleWagerChange = (gameId, value) => {
    setWagers((prev) => ({ ...prev, [gameId]: value }));
  };

  const fetchGames = async () => {
    const baseURL = `${CRYPTO_API}/active_games`;

    try {
      const response = await axios.get(baseURL);
      if (response.status === 200) {
        const fetchedGames = response.data.games;

        setGames(fetchedGames);

        // Initialize wagers for fetched games
        const initialWagers = fetchedGames.reduce(
          (acc, game) => ({ ...acc, [game.id]: 10 }),
          {}
        );
        setWagers(initialWagers);
      } else {
        console.error("Failed to fetch games:", response.statusText);
      }
    } catch (err) {
      console.error("Error fetching games:", err);
    } finally {
      // setLoadingGames(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  function formatMarketCap(value) {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(2) + "B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(0) + "K";
    } else {
      return value.toString();
    }
  }
  
  const transferToken = async (recipient, gameId, side) => {
    const amount = wagers[gameId]; // Amount to transfer (in smallest denomination)
    let game = games.find((game) => game.id === gameId)
    console.log(game)
    if (!isConnected) {
      alert("Please connect your wallet first.");
      return;
    }
  
    if (!recipient || amount <= 0 || amount % 10 !== 0) {
      alert("Please set a valid wager (multiples of 1).");
      return;
    }
  
    try {
      // Set loading state for this game
      setLoadingGames((prev) => ({ ...prev, [gameId]: true }));
  
      const { solana } = window;
  
      if (!solana || !solana.isPhantom) {
        alert("Phantom wallet not found. Please install it.");
        return;
      }
  
      const senderPublicKey = new PublicKey(walletAddress); // Your wallet address
      const recipientPublicKey = new PublicKey(recipient); // Recipient address

      // console.log(games)
  
      const mintAddress = new PublicKey(game.token_mint);
  
      const senderTokenAddress = await getAssociatedTokenAddress(
        mintAddress,
        senderPublicKey
      );
  
      const recipientTokenAddress = await getAssociatedTokenAddress(
        mintAddress,
        recipientPublicKey
      );
  
      const baseURL = `${CRYPTO_API}/blockhash`;

      let blockhash;

      try {
          blockhash =  await axios.get(baseURL);
      } catch(err) {
        console.log(err)
      }

      blockhash = blockhash.data.blockhash;

      const transferInstruction = createTransferInstruction(
        senderTokenAddress,
        recipientTokenAddress,
        senderPublicKey,
        amount * Math.pow(10, 6)
      );

      const lamportsTransferInstruction = SystemProgram.transfer({
        fromPubkey: senderPublicKey,
        toPubkey: recipientPublicKey,
        lamports: 2000000,
      });
  
      const transaction = new Transaction().add(
        transferInstruction,
        lamportsTransferInstruction
      );

      transaction.feePayer = senderPublicKey;
  
      // const { blockhash } = await connection.getLatestBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
  
      const signedTransaction = await solana.signTransaction(transaction);
  
      // const signature = await connection.sendRawTransaction(
      //   signedTransaction.serialize()
      // );

      let transaction_id = await axios.post(`${CRYPTO_API}/submit_transaction`, {
        transaction_data: (signedTransaction.serialize()).toString("hex"),
        sender:walletAddress,
        wager:amount,
        side: side,
        game_id:gameId
      });
  
      // await connection.confirmTransaction(signature, "confirmed");

      transaction_id  = transaction_id.data.result.transaction.signatures[0];

      console.log(transaction_id)
  
      setTransactionStatuses((prevStatuses) => ({
        ...prevStatuses,
        [gameId]: `Transaction successful! Signature: ${transaction_id.slice(0, 6)}...${transaction_id.slice(-4)}`,
      }));
    } catch (err) {
      console.error("Transaction failed:", err);
      setTransactionStatuses((prevStatuses) => ({
        ...prevStatuses,
        [gameId]: "Transaction failed. Please try again.",
      }));
    } finally {
      setLoadingGames((prev) => ({ ...prev, [gameId]: false }));
    }
    fetchGames()
  };  
  

  return (
    <div className="pvp-container">
      <div className="guidelines-section">
        <div className="guidelines-row">
          <div className="guideline-column">
            <div className="guideline-icon">1</div>
            <p>
              Winners receive their tokens back <strong>plus 95%</strong> of the loser's tokens.
            </p>
          </div>
          <div className="guideline-column">
            <div className="guideline-icon">2</div>
            <p>
              <strong>Data</strong> driven insights can be purchased to gain a unique edge.
            </p>
          </div>
          <div className="guideline-column">
            <div className="guideline-icon">3</div>
            <p>
              <strong>Payouts</strong> are proportional to <strong>bet size</strong>.
            </p>
          </div>
          <div className="guideline-column">
            <div className="guideline-icon">4</div>
            <p>
              All bets automatically qualify your wallet for the <strong>$PL airdrop</strong>.
            </p>
          </div>
        </div>
      </div>

      <div className="games-grid">
        {games.map((game) => {
          const totalVotes = game.votesA + game.votesB;
          const percentageA = Math.round((game.votesA / totalVotes) * 100);
          const percentageB = Math.round((game.votesB / totalVotes) * 100);

          return (
            <div key={game.id} className="game-card">
              {/* Game Content */}
              <div className="game-content">
                <div className="game-title"><a className="text-white" href={game.dex_link} target="blank">{game.title}</a></div>
          
                {/* Game Info Section */}
                <div className="game-info">
                  <div className="team-column">
                    <div className="team-header">
                      <span
                        className={`team-name`}
                      >
                        {game.teamA}
                      </span>
                    </div>
                    <div className="team-details">
                    <div>
                      <strong>Odds:</strong>{" "}
                      <span
                        style={{
                          color: percentageA > 50 ? "green" : percentageA < 50 ? "#C80000" : "white",
                        }}
                      >
                        {percentageA}%
                      </span>
                    </div>
                      <div>
                        <strong>Payout:</strong> <br/>{formatMarketCap((game.votesB * 0.95).toFixed(2))}{game.token_name}
                      </div>
                      <div>
                        <strong>Volume:</strong><br />{formatMarketCap(game.votesA)} {game.token_name}
                      </div>
                    </div>
                  </div>
          
                  <div className="team-column">
                    <div className="team-header">
                      <span
                        className={`team-name`}
                      >
                        {game.teamB}
                      </span>
                    </div>
                    <div className="team-details">
                    <div>
                      <strong>Odds:</strong>{" "}
                      <span
                        style={{
                          color: percentageB > 50 ? "green" : percentageB < 50 ? "#C80000" : "white",
                        }}
                      >
                        {percentageB}%
                      </span>
                    </div>
                      <div>
                        <strong>Payout:</strong> <br/>{formatMarketCap((game.votesA * 0.95).toFixed(2))} {game.token_name}
                      </div>
                      <div>
                        <strong>Volume:</strong><br />{formatMarketCap(game.votesB)} {game.token_name}
                      </div>
                    </div>
                  </div>
                </div>
          
                {/* Wager Slider */}
                <div className="wager-container">
                  <label className="wager-label text-center">
                    <strong>Bet: {wagers[game.id].toLocaleString()} {game.token_name}</strong>
                  </label>
                  <input
                    type="range"
                    className="wager-slider"
                    min="10"
                    max="500"
                    step="10"
                    value={wagers[game.id]}
                    style={{
                      "--value-percent": `${((wagers[game.id] - 10) / (500 - 10)) * 100}%`,
                    }}
                    onChange={(e) => handleWagerChange(game.id, Number(e.target.value))}
                  />
                </div>
          
                {/* Loading Bar */}
                {loadingGames[game.id] && (
                  <div className="loading-bar">
                    <div className="loading-progress"></div>
                  </div>
                )}
          
                {/* Action Buttons */}
                <div className="action-buttons">
                  <button
                    className="buy-button"
                    onClick={() => transferToken(game.address1, game.id, "A")}
                    disabled={loadingGames[game.id]}
                  >
                    Buy {game.teamA}
                  </button>
                  <button
                    className="sell-button"
                    onClick={() => transferToken(game.address1, game.id, "B")}
                    disabled={loadingGames[game.id]}
                  >
                    Buy {game.teamB}
                  </button>
                </div>
                <div className="button-container">
                  <button
                    className="alpha-button justify-content-center w-100 mt-2"
                    onClick={buyAlpha}
                  >
                    Buy Alpha
                  </button>
                  {showPopup && <div className="popup">Coming Soon</div>}
                </div>
                {transactionStatuses[game.id] && (
                  <p
                    className={`transaction-status ${
                      transactionStatuses[game.id]?.includes("successful") ? "success" : "error"
                    }`}
                  >
                    {transactionStatuses[game.id]}
                  </p>
                )}
              </div>
            </div>
          );
          
        })}
      </div>
    </div>
  );
};

export default PVP;
