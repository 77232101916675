import React from 'react';
import SplashNav from '../components/SplashNav';
import data from '../data/mapsSample.json'; // Import sample data
import './MineSample.css'; // CSS file for styling

const MineSample = () => {
    return (
        <div className="mine-sample-page">
            <div className="page-content fade-in row justify-content-center">
            <img src="/Mine.png" alt="Mine Logo" className="logo-image" />
            <div className="title-section">
                    <h2 className="page-title">Mine: Custom Mining Solutions</h2>
                    <h6 className="subtitle">The Data Below Represents a Limited Sample of the capabilities of the Mine Program</h6>
                    <p className="disclaimer">
                        The displayed data was created with the query: Digital Marketing Firms in Chicago IL. The capabilities of the mine program are vast and results are dependant on the degree of detail provided by the client.
                    </p>
                </div>
                <div className="data-cards">
                    {data.map((item) => (
                        <div key={item._id} className="data-card">
                            <h3>{item.name}</h3>
                            <p><strong>Rating:</strong> {item.rating} ⭐ ({item.reviews} reviews)</p>
                            <p><strong>Address:</strong> {item.address}</p>
                            <p><strong>Phone:</strong> {item.phone}</p>
                            <p><strong>Website:</strong> <a href={item.website} target="_blank" rel="noopener noreferrer">{item.website}</a></p>
                            <p><strong>Emails:</strong></p>
                            <ul>
                                {item.emails.map((email, index) => (
                                    <li key={index}><a href={email}>{email.replace("mailto:", "")}</a></li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MineSample;
